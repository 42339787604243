import * as noUiSlider from 'nouislider';
import moment from 'moment';
import VueI18n from 'vue-i18n'

window.Vue = require('vue').default;
import Vuex from 'vuex';
import UUID from "vue-uuid";
import vSelect from "vue-select";

Vue.use(moment);
Vue.prototype.moment = moment;

Vue.component("v-select", vSelect);
Vue.use(Vuex);
Vue.use(UUID);
Vue.use(VueI18n);

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

const appStore = new Vuex.Store({
    state() {
        return {
            init: false,
            refFilter: {
                price: [25, 200],
                gearbox: 3,
                active: 0,
                engine_capacity: [1.2, 2.0],
                engine_type: [],
                capacity: []
            },
            filter: {
                price: [25, 200],
                gearbox: 3,
                engine_capacity: [1.2, 2.0],
                engine_type: [],
                capacity: [],
                active: 0
            },
            type: 'rent',
            params: {
                from_city: '',
                from_date: '',
                from_time: '10:00',
                to_city: '',
                to_date: '',
                to_time: '10:00',
                total_days: 0,
                car_price: 0,
                delivery: 0,
                discount: 0,
                selected_car: null,
                car: null,
                selected_transfer: null,
                transfer: null,
                insurance: [],
                additional: [],
                selected_class: null,
                sum: 0
            },
            personal: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                messengers: [],
                additional: '',
                politics: false,
                rules: false
            },
            options: {
                city: [],
                time: [],
                additional: [],
                insurance: [],
                cars: [],
                gearbox: [],
                capacity: [],
                class: [],
                transfers: [],
                engine: [],
                discount: [],
                max_discount: 0
            },
            dateConfig: {
                altInput: true,
                //defaultDate: new Date(),
                altFormat: "d.m.Y",
                dateFormat: "Y-m-d",
            },
            priceConfig: {
                step: 1,
                connect: true,
                tooltips: false,
                range: {
                    'min': 25,
                    'max': 200
                }
            },
            engineConfig: {
                step: 0.1,
                connect: true,
                tooltips: false,
                range: {
                    'min': 1.2,
                    'max': 2.0
                }
            },
        }
    },
    getters: {
        getKlass: (state, getters) => {
            let cars = getters.getCars;
            for (let [key, obj] of Object.entries(state.options.class)) {

                let carsCount = cars.filter((car) => {
                    let classInclude = false;
                    car.classes.forEach((elem) => {
                        if (elem.id === obj.id)
                            classInclude = true;
                    })
                    return classInclude;
                })


                /*if (cars.filter(car => car.class_id === obj.id).length === 0)
                    state.options.class[key].disabled = 1;
                else
                    state.options.class[key].disabled = 0;*/
                if (carsCount.length === 0)
                    state.options.class[key].disabled = 1;
                else
                    state.options.class[key].disabled = 0;
            }
            return state.options.class;

        },
        getCars: (state) => {
            let cars = [];
            if (state.filter.active === 1) {
                cars = state.options.cars.filter(car => parseFloat(car.price) >= parseFloat(state.filter.price[0]) && parseFloat(car.price) <= parseFloat(state.filter.price[1]))
                cars = cars.filter(car => parseFloat(car.engine_capacity) >= parseFloat(state.filter.engine_capacity[0]) && parseFloat(car.engine_capacity) <= parseFloat(state.filter.engine_capacity[1]))

                if (state.filter.gearbox !== 3)
                    cars = cars.filter(car => car.gearbox_id === state.filter.gearbox);

                if (state.filter.engine_type.length) {
                    cars = cars.filter(car => state.filter.engine_type.indexOf(car.engine_id) !== -1)
                }
                if (state.filter.capacity.length) {
                    cars = cars.filter(car => state.filter.capacity.indexOf(car.capacity_id) !== -1)
                }
            } else
                cars = state.options.cars;


            return cars;
        },
        /*getCar: (state) => (id) => {
            return state.options.cars.find(car => car.id === id);
        }*/
        getCityFrom: (state) => {
            return state.options.city.find(city => city.id === state.params.from_city);
        },
        getCar: (state) => {
            return state.options.cars.find(car => car.id === state.params.selected_car);
        },
        getGearbox: (state) => (id) => {
            return state.options.gearbox.find(gearbox => gearbox.id === id);
        },
        getEngine: (state) => (id) => {
            return state.options.engine.find(engine => engine.id === id);
        },
        getCapacity: (state) => (id) => {
            return state.options.capacity.find(capacity => capacity.id === id);
        },
        getInsure: (state) => (id) => {
            return state.options.insurance.find(insurance => insurance.id === id);
        },
        getAdditional: (state) => (id) => {
            return state.options.additional.find(additional => additional.id === id);
        }
    },
    mutations: {
        setKlass(state, id) {
            state.params.selected_class = id;
        },
        setCar(state, id) {
            let car = state.options.cars.find(car => car.id === id);

            state.params.selected_car = id;
            state.params.car = id;

            state.params.transfer = null;
            state.params.selected_transfer = null;

            state.params.car_price = parseFloat(car.price);

        },
        selectTransfer(state, id) {
            let transfer = state.options.transfers.find(transfer => transfer.id === id);

            state.params.selected_transfer = id;
            state.params.transfer = transfer;

            state.params.selected_car = null;
            state.params.car = null;
            //state.params.car_price = parseFloat(car.price);

        },
        calculateSum(state) {
            let carSum = state.params.car_price * state.params.total_days;
            if (state.params.discount > 0)
                carSum -= carSum * state.params.discount / 100

            carSum += state.params.delivery;

            let insures = state.options.insurance.filter(insurance => state.params.insurance.indexOf(insurance.id) !== -1);
            for (let [key, obj] of Object.entries(insures)) {
                if (parseFloat(obj.price) > 0)
                    carSum += parseFloat(obj.price) * state.params.total_days;
            }
            let additionals = state.options.additional.filter(additional => state.params.additional.indexOf(additional.id) !== -1);
            for (let [key, obj] of Object.entries(additionals)) {
                if (parseFloat(obj.price) > 0)
                    carSum += parseFloat(obj.price) * state.params.total_days;
            }

            state.params.sum = carSum;

        },
        setType(state, type) {
            state.type = type;
        },
        resetFilter(state) {
            state.filter = JSON.parse(JSON.stringify(state.refFilter));
        },
        setFilter(state, filter) {
            state.filter = filter;
        },
        setParams(state, params) {
            state.params = params;
        },
        setPersonal(state, personal) {
            state.personal = personal;
        },
        loadParams(state, params) {
            state.params.from_date = moment().format('YYYY-MM-DD');
            state.params.to_date = moment().add(7, 'days').format('YYYY-MM-DD');
            state.params.total_days = 7;

            state.options.discount = params.discountArr;
            state.options.max_discount = params.max_discount;
            state.options.transfers = params.transfers;

            state.options.city = params.cityArr;
            state.options.time = params.timeOptions;
            state.params.from_city = state.options.city[2].id;
            state.params.to_city = state.options.city[1].id;

            state.options.insurance = params.insurance;

            for (let [key, obj] of Object.entries(params.insurance)) {
                if (obj.required === 1)
                    state.params.insurance.push(obj.id);
            }

            state.options.additional = params.additional;
            state.options.cars = params.cars;

            state.options.capacity = params.carCapacity;
            state.options.class = params.carClass;
            state.options.engine = params.engineType;
            state.options.gearbox = params.gearboxType;

            state.filter.price = params.price;
            state.refFilter.price = params.price;
            state.priceConfig.range.min = parseFloat(params.price[0]);
            state.priceConfig.range.max = parseFloat(params.price[1]);

            state.filter.engine_capacity = params.engine;
            state.refFilter.engine_capacity = params.engine;
            state.engineConfig.range.min = parseFloat(params.engine[0]);
            state.engineConfig.range.max = parseFloat(params.engine[1]);

            state.init = true;
        }
    },
    actions: {}
})

function loadLocaleMessages() {
    const locales = require.context(
        "../lang",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );

    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

const i18n = new VueI18n({
    locale: window.currentLang,
    fallbackLocale: "en",
    messages: loadLocaleMessages()
});

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('v-nus', {
    template: '<div :id="\'vue-nouislider-\' + id"></div>',
    props: {
        'id': {
            type: String,
            default: function () {
                // stackoverflow.com/questions/10726909/
                return Math.random().toString(36).substr(2, 4)
            }
        },
        'config': Object,
        'value': Array,
        'log': Boolean
    },
    data: function () {
        return {
            init: false
        }
    },
    computed: {
        slider: function () {
            return document.getElementById('vue-nouislider-' + this.id)
        }
    },
    mounted: function () {
        var vnus = this
        vnus.config.start = vnus.value
        noUiSlider.create(vnus.slider, vnus.config)
        vnus.slider.noUiSlider.on('update', function (values, handle) {
            vnus.$emit('update', values)
            if (vnus.log) window.console.log('[vnus]<' + handle + '>' + values)
        })
    },
    watch: {/*
        'config': function (nv) {
            var nus = this.slider.noUiSlider.get();
            nus.updateOptions({
                range: this.config.range
            })
        },*/
        config: {
            handler(val) {
                this.slider.noUiSlider.updateOptions({
                    range: this.config.range
                })
            }
            ,
            deep: true
        },
        'value': function (nv) {
            if (this.init) {
                var nus = this.slider.noUiSlider.get()
                if (!Array.isArray(nus)) nus = [nus]
                if (JSON.stringify(nus) !== JSON.stringify(nv)) this.slider.noUiSlider.set(nv)
            } else {
                this.init = true
            }
        }
    }
})

if (document.getElementById('app') !== null) {
    const app = new Vue({
        i18n,
        el: '#app',
        store: appStore,
        mounted() {
            this.loadParams();
        },
        methods: {
            loadParams() {
                const vm = this;
                axios.get('/get-params')
                    .then((response) => response.data)
                    .then((data) => {
                        vm.$store.commit('loadParams', data);
                    })
            }
        }
    });
}
