import './bootstrap';
import './vue';
import './components/itc-select';


//import customSelect from 'custom-select';
//import PerfectScrollbar from 'perfect-scrollbar';
//import flatpickr from "flatpickr";

import * as noUiSlider from 'nouislider';


//customSelect('select');
/*
let pselems = document.querySelectorAll('.v-select ul');
pselems.forEach(function (elem){
   new PerfectScrollbar(elem);
});
*/

/*var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    popoverTriggerEl.addEventListener('click', (elem)=>{
        elem.classList.toggle('--open')
    })
    return new bootstrap.Popover(popoverTriggerEl, {
        html: true,
        content: popoverTriggerEl.nextElementSibling
    })
})*/
/*const popoverButtons = document.querySelectorAll('[data-bs-toggle="popover"]');
popoverButtons.forEach((elem) => {
    elem.addEventListener('click', (event) => {
        elem.classList.toggle('--open')
    })
    new bootstrap.Popover(elem, {
        html: true,
        content: elem.nextElementSibling
    })
});*/
/*flatpickr("[data-date_input]",{
    altInput: true,
    defaultDate: new Date(),
    altFormat: "d.m.Y",
    dateFormat: "Y-m-d",
});*/

/*const priceSlider = document.querySelector("#priceSlider");
if(priceSlider) {
    const valuePriceSlider = [];
    const priceMin = parseInt(priceSlider.getAttribute('data-min_value'));
    const priceMax = parseInt(priceSlider.getAttribute('data-max_value'));

    const curPriceMin = parseInt(priceSlider.getAttribute('data-cur-min_value'));
    const curPriceMax = parseInt(priceSlider.getAttribute('data-cur-max_value'));

    for (let i = priceMin; i <= priceMax; i++) valuePriceSlider.push(i);

    const priceFormat = {
        to: function (value) {
            return `${valuePriceSlider[Math.round(value)]} €`;
        },
        from: function (value) {
            return valuePriceSlider.indexOf(Number(value));
        },
    };


    if (priceSlider)
        noUiSlider.create(priceSlider, {
            start: [curPriceMin, curPriceMax],
            connect: true,
            tooltips: false,
            range: {
                min: 0,
                max: valuePriceSlider.length - 1,
            },

            step: 1,
            format: priceFormat,
        }).on('update', function (values, handle, unencoded) {
            let start = values[0];
            let end = values[1];
            if (typeof start === 'string')
                document.querySelector('[name="price-from"]').value = start;
                document.querySelector('[name="price-to"]').value = end;
        });
}*/
const engineSlider = document.querySelector("#engineSlider");
if (engineSlider) {
    const valueEngineSlider = [];
    const engineMin = parseFloat(engineSlider.getAttribute('data-min_value'));
    const engineMax = parseFloat(engineSlider.getAttribute('data-max_value'));

    const curEngineMin = parseFloat(engineSlider.getAttribute('data-cur-min_value'));
    const curEngineMax = parseFloat(engineSlider.getAttribute('data-cur-max_value'));


    for (let i = engineMin * 10; i <= engineMax * 10; i++) valueEngineSlider.push(i);

    const engineFormat = {
        to: function (value) {
            return `${valueEngineSlider[Math.round(value)] / 10} л`;
        },
        from: function (value) {
            return valueEngineSlider.indexOf(Number(value));
        },
    };


    if (engineSlider)
        noUiSlider.create(engineSlider, {
            start: [engineMin * 10, engineMax * 10],
            connect: true,
            tooltips: false,
            range: {
                min: 0,
                max: valueEngineSlider.length - 1,
            },

            step: 1,
            format: engineFormat,
        }).on('update', function (values, handle, unencoded) {
            let start = values[0];
            let end = values[1];
            if (typeof start === 'string')
                document.querySelector('[name="engine-from"]').value = start;
            document.querySelector('[name="engine-to"]').value = end;
        });
}
const xhrFormsSend = document.querySelectorAll('[data-xhr="true"]');

if (xhrFormsSend.length) {
    xhrFormsSend.forEach((form) => {

        form.addEventListener('submit', (e) => {
            e.preventDefault();
            form.querySelector('[type="submit"]').setAttribute('disabled', 'disabled');

            let formData = new FormData(form);
            axios.post("/send-contact", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((res) => {
                form.querySelector('[type="submit"]').removeAttribute('disabled');
                form.reset();
                let sendModalElem = document.getElementById('contactSendModal');
                if (sendModalElem) {
                    let sendModal = bootstrap.Modal.getOrCreateInstance(sendModalElem);
                    sendModal.show();
                }

            })
                .catch((err) => {
                    alert('Send error...')
                });
        })
    })
}
